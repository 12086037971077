import { ClassNames } from "@emotion/react";
import { useTimeoutEffect } from "@react-hookz/web";
import type { FunctionComponent, ReactNode } from "react";
import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { useAppBarStickyHeightValue } from "scmp-app/components/app-bar/hooks";
import { useIntersection } from "scmp-app/lib/hooks";

import { Container, getStickyStyle, Placeholder } from "./styles";

type Props = {
  children?: ReactNode;
  className?: string;
  withSticky?: boolean;
};

export const ArticleHeaderAdSlot: FunctionComponent<Props> = ({
  children,
  className,
  withSticky = true,
}) => {
  const { appBarStickyHeight } = useAppBarStickyHeightValue();
  const [hasShownStickyAd, setHasShownStickyAd] = useState(false);

  const [shouldSticky, setShouldSticky] = useState(false);

  const nodeReference = useRef<HTMLDivElement>(null);
  const [animateStart, setAnimateStart] = useState(false);
  useTimeoutEffect(
    () => {
      setShouldSticky(false);
    },
    shouldSticky && animateStart ? 1500 : undefined,
  );

  const { captureIntersectionElement, intersection } = useIntersection({});
  useEffect(() => {
    if (hasShownStickyAd || !intersection || !withSticky) return;
    else if (intersection.intersectionRatio === 0) {
      // TODO: check if the effect is working as expected when implemented perpetual scroll
      setShouldSticky(true);
      setHasShownStickyAd(true);
    }
  }, [hasShownStickyAd, intersection, withSticky]);

  return (
    <ClassNames>
      {({ css }) => (
        <CSSTransition
          classNames={{
            enterActive: css`
              opacity: 0;
            `,
            enterDone: css`
              ${getStickyStyle(appBarStickyHeight)}
              opacity: 1;
            `,
            exit: css`
              ${getStickyStyle(appBarStickyHeight)}
              opacity: 0;
            `,
            exitDone: css`
              opacity: 1;
            `,
          }}
          in={shouldSticky}
          nodeRef={nodeReference}
          onEntered={() => {
            setAnimateStart(true);
          }}
          timeout={300}
        >
          <Container className={className} ref={nodeReference}>
            <Placeholder ref={captureIntersectionElement}>{children}</Placeholder>
          </Container>
        </CSSTransition>
      )}
    </ClassNames>
  );
};

ArticleHeaderAdSlot.displayName = "ArticleHeaderAdSlot";
