import { theme } from "@product/scmp-sdk";
import type { FunctionComponent, ReactNode } from "react";

import { useAppBarStickyHeightValue } from "scmp-app/components/app-bar/hooks";
import type { DefaultComponentsProvider } from "scmp-app/components/article/article-render";

import { AdSlot, Container, Section } from "./styles";

type Props = {
  children?: (props: Record<string, boolean>) => ReactNode;
  className?: string;
  customSlot?: ReactNode;
  entityId: string;
  provider: DefaultComponentsProvider;
  shouldRender: boolean;
  shouldRenderWidgets: Record<string, boolean>;
};

export const ArticleContentRightSection: FunctionComponent<Props> = ({
  children,
  className,
  customSlot,
  entityId,
  provider,
  shouldRender,
  shouldRenderWidgets,
}) => {
  const { appBarStickyHeight } = useAppBarStickyHeightValue();

  if (!shouldRender) return null;
  return (
    <Container className={className}>
      <AdSlot $stickyStackedHeight={appBarStickyHeight}>
        {provider.adslot({
          adUnit: "d_lrec1",
          autoRefreshOptions: {
            scrollBackRefreshOptions: { slotArticleEntityId: entityId },
          },
          breakpoint: theme.breakpoints.up("desktop"),
          sizes: [
            [300, 250],
            [300, 600],
          ],
        })}
      </AdSlot>

      {customSlot && <Section>{customSlot}</Section>}
      {children && <Section>{children(shouldRenderWidgets)}</Section>}
    </Container>
  );
};
