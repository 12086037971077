import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import type { WithStickyStackedHeightProps } from "shared/lib/styles";

import {
  AdSlotContainer,
  StyledSlot,
} from "scmp-app/components/advertisement/ad-slots/ad-slot/styles";

export const Container = styled.div`
  display: none;

  ${theme.breakpoints.up("desktop")} {
    display: flex;
    flex-flow: column nowrap;
    gap: 40px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex: auto;
  flex-flow: column nowrap;
  gap: 40px;
  justify-content: space-between;

  :empty {
    display: none;
  }
`;

export const AdSlot = styled.div<WithStickyStackedHeightProps>`
  block-size: 1000px;

  && ${AdSlotContainer} {
    display: flex;
    align-items: flex-start;

    block-size: 100%;

    ${StyledSlot} {
      position: sticky;
      inset-block-start: ${props => props.$stickyStackedHeight + 40}px;

      block-size: auto;
    }
  }
`;
