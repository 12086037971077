/* eslint-disable max-lines */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontHeebo, fontMerriweather, fontRoboto, theme } from "@product/scmp-sdk";

import { StyledAnchor } from "shared/components/common/base-link/styles";
import { section } from "shared/data";
import type { WithStickyStackedHeightProps } from "shared/lib/styles";
import { setFullContentWidthStyles, setFullViewportWidthStyles } from "shared/lib/styles";

import {
  AdSlotContainer,
  Container as AdSlotOuterContainer,
  StyledSlot,
} from "scmp-app/components/advertisement/ad-slots/ad-slot/styles";
import { StyledAuthorAvatar } from "scmp-app/components/article/article-author-block/author-avatars/styles";
import {
  AndSeparator,
  AuthorLocation,
  AuthorNamesContainer,
  CommaSeparator,
} from "scmp-app/components/article/article-author-block/author-names/styles";
import {
  Item,
  LatestCorrectionDate,
  LatestCorrectionDateContainer,
} from "scmp-app/components/article/article-corrections-widget/styles";
import { ArticleHeaderAdSlot } from "scmp-app/components/article/article-header-ad-slot";
import {
  ArticleImageContainer,
  DescriptionContainer as ArticleImageDescriptionContainer,
  DescriptionContainer,
} from "scmp-app/components/article/article-image/styles";
import { ArticleLeadingVideoContainer } from "scmp-app/components/article/article-leading/styles";
import { ArticleContentRightSection } from "scmp-app/components/article/article-render/article-content/article-content-right-section";
import { pageStyle } from "scmp-app/components/article/article-render/article-content/standard-styles";
import {
  StyledAdSlot,
  StyledArticleCarouselWidget,
  StyledArticleFurtherReadingWidget,
  StyledContentHeadline,
} from "scmp-app/components/article/article-render/styles";
import {
  SectionLink,
  SectionTagsSpacer,
} from "scmp-app/components/article/article-sections/styles";
import { StyledTopicLink } from "scmp-app/components/article/article-topic/styles";
import { ArticleTrustLabel } from "scmp-app/components/article/article-trust-label";
import { Container as ArticleTrustLabelContainer } from "scmp-app/components/article/article-trust-label/styles";
import { StyledImage as AuthorAvatarImage } from "scmp-app/components/author/author-avatar/styles";
import {
  StyledEntityLink as AuthorNameEntityLink,
  StyledAuthorName,
} from "scmp-app/components/author/author-name/styles";
import { ContentNewsletterWidget } from "scmp-app/components/content/content-newsletter-widget";
import { InstagramContainer } from "scmp-app/components/schema-render/common/blockquote/styles";
import { Container as PhotoGalleryContainer } from "scmp-app/components/schema-render/common/div/photo-gallery/styles";
import { StyledDiv as StyledDivContainer } from "scmp-app/components/schema-render/common/div/styles";
import { StyledIframe as StyledGenericIframe } from "scmp-app/components/schema-render/common/iframe/generic-iframe/styles";
import { StyledParagraph } from "scmp-app/components/schema-render/common/paragraph/styles";
import {
  Caption,
  StyledSummary,
  StyledTable,
  StyledContainer as StyledTableContainer,
  TableFooter,
} from "scmp-app/components/schema-render/common/table/styles";
import {
  StyledLi,
  StyledTbody,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr,
  StyledUl,
} from "scmp-app/components/schema-render/content/styles";
import {
  StyledVideoRecorder,
  VideoTitle,
  VideoTitleContainer,
} from "scmp-app/components/scmp-youtube-video/scmp-youtube-video-footer/styles";
import {
  Container as SCMPYouTubeContainer,
  VideoContainer,
} from "scmp-app/components/scmp-youtube-video/scmp-youtube-video-player/styles";
import {
  PreviewContainer,
  PreviewContentContainer,
  PreviewImage,
} from "scmp-app/components/scmp-youtube-video/scmp-youtube-video-preview/styles";
import {
  SCMPYoutubeVideoContainer,
  StyledSCMPYouTubeVideoFooter,
} from "scmp-app/components/scmp-youtube-video/styles";
import {
  SponsorName,
  SponsorTagContainer,
  SponsorType,
} from "scmp-app/components/sponsor-tag/styles";

export const StyledArticleTrustLabel = styled(ArticleTrustLabel)`
  text-decoration: none;

  ${ArticleTrustLabelContainer} {
    flex-flow: row-reverse;

    column-gap: 8px;

    ${theme.breakpoints.only("mobile")} {
      span {
        display: none;
      }
    }
  }
`;

export const GenericArticleContainer = styled.div`
  display: grid;
  grid:
    "header-container" min-content
    "leading" min-content
    "content-container" min-content
    "widgets-bottom" min-content
    / 100%;

  inline-size: 100%;
  max-inline-size: 600px;
  margin-inline: auto;

  ${theme.breakpoints.up("tablet")} {
    max-inline-size: 800px;
  }

  ${theme.breakpoints.up("desktop")} {
    grid:
      "header-ad header-ad header-ad header-ad" min-content
      ". header-container header-container header-container" min-content
      ". leading . right" min-content
      ". content-container . right" min-content
      ". . . right" min-content
      ". widgets-bottom widgets-bottom widgets-bottom" min-content
      / minmax(0, 92px) minmax(650px, 776px) 40px 300px;

    min-inline-size: 990px;
    max-inline-size: 1208px;
    margin-inline: auto;
  }
`;

export const HeaderContainer = styled.div`
  grid-area: header-container;

  display: grid;
  grid:
    "topic-and-section" min-content
    "columnist" min-content
    "headline" min-content
    "sub-headline" min-content
    "sponsor" min-content
    "reading-time" min-content
    / 100%;

  inline-size: 100%;

  ${theme.breakpoints.up("tablet")} {
    grid:
      "topic-and-section" min-content
      "columnist" min-content
      "headline" min-content
      "sub-headline" min-content
      "sponsor" min-content
      "reading-time" min-content
      "action-widgets" min-content
      / 100%;
  }

  ${theme.breakpoints.up("desktop")} {
    grid:
      ". topic-and-section . ." min-content
      ". columnist . ." min-content
      ". headline . ." min-content
      ". sub-headline . ." min-content
      ". sponsor . ." min-content
      ". reading-time . ." min-content
      ". action-widgets . ." min-content
      / minmax(0, 60px) 904px 92px minmax(0, 60px);
  }
`;

export const ContentContainer = styled.div`
  grid-area: content-container;

  display: grid;
  grid:
    "author" min-content
    "publish-datetime" min-content
    "mobile-content-header-ad" min-content
    "series-inline" min-content
    "correction" min-content
    "content" min-content
    "paywall" min-content
    "series-carousel" min-content
    "author-detail" min-content
    "mobile-footer-ad" min-content
    "bottom-carousel" min-content
    / 100%;

  inline-size: 100%;

  ${theme.breakpoints.up("tablet")} {
    grid:
      "author" min-content
      "publish-datetime" min-content
      "mobile-content-header-ad" min-content
      "series-inline" min-content
      "correction" min-content
      "content" min-content
      "paywall" min-content
      "series-carousel" min-content
      "author-detail" min-content
      "bottom-carousel" min-content
      / 100%;
  }

  ${theme.breakpoints.up("desktop")} {
    grid:
      ". author ." min-content
      ". publish-datetime ." min-content
      ". series-inline ." min-content
      ". correction ." min-content
      ". content ." min-content
      "paywall paywall paywall" min-content
      ". series-carousel ." min-content
      ". author-detail ." min-content
      / minmax(0, 60px) minmax(650px, 776px) minmax(0, 60px);
  }
`;

export const RightSectionContainer = styled.div<WithStickyStackedHeightProps>`
  display: flex;
  flex-flow: column nowrap;
  gap: 40px;

  ${AdSlotOuterContainer} {
    block-size: auto;

    ${AdSlotContainer} {
      display: flex;
      align-items: flex-start;

      block-size: 100%;

      ${StyledSlot}:not(${StyledArticleFurtherReadingWidget} ${StyledSlot}) {
        position: sticky;
        inset-block-start: ${props => props.$stickyStackedHeight + 40}px;

        block-size: auto;
      }
    }
  }
`;

export const FurtherReadingRightSectionContainer = styled(RightSectionContainer)`
  flex-grow: 1;

  ${StyledAdSlot} {
    block-size: 100%;
  }
`;

export const RightSectionPlaceholder = styled.div`
  flex: auto;
`;

export const StyledArticleContentRightSection = styled(ArticleContentRightSection)`
  grid-area: right;

  margin-block-start: 32px;

  ${theme.breakpoints.between("mobile", "desktop")} {
    display: none;
  }
`;

export const TopicAndSection = styled.div`
  grid-area: topic-and-section;

  display: flex;
  flex-flow: row wrap;
  gap: 8px 12px;
  align-items: flex-start;

  margin-block-start: 20px;

  ${theme.breakpoints.up("tablet")} {
    flex-flow: row nowrap;
    gap: 16px;

    margin-block-start: 40px;
  }

  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 60px;
  }

  ${SectionTagsSpacer} {
    margin-inline: 4px;
  }

  ${SectionLink} {
    font-size: 12px;
    line-height: normal;

    ${theme.breakpoints.up("tablet")} {
      font-size: 14px;
    }

    span {
      color: #000000;
      font-weight: 400;
      font-size: inherit;
      font-family: ${fontRoboto};
      font-style: italic;
      line-height: inherit;
    }

    span:hover {
      color: #2c4692;
    }
  }

  ${StyledTopicLink} {
    :hover {
      color: #2c4692;
    }
  }
`;

export const Columnist = styled.div`
  grid-area: columnist;

  display: flex;
  flex-flow: row wrap;
  gap: 12px;
  align-items: center;

  margin-block-start: 16px;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 20px;
  }

  ${StyledAuthorAvatar} {
    inline-size: 40px;
    block-size: 40px;

    ${AuthorAvatarImage} {
      padding: 0;
    }
  }

  ${AuthorNamesContainer} {
    line-height: normal;
    ${StyledAuthorName}, ${AuthorNameEntityLink}, ${CommaSeparator}, ${AndSeparator} {
      color: #000000;
      font-weight: 700;
      font-size: 14px;
      font-family: ${fontRoboto};
      font-style: normal;
      line-height: normal;
      font-variant-numeric: lining-nums proportional-nums;
    }

    ${CommaSeparator}, ${AndSeparator} {
      font-weight: 400;
    }
  }
`;

type HeadlineProps = {
  $magazineVariant?: MagazineVariant;
};

type MagazineVariant =
  | typeof section.postMagazine.entityId
  | typeof section.thisWeekInAsia.entityId;

export const Headline = styled.div<HeadlineProps>`
  grid-area: headline;

  margin-block-start: 16px;

  color: #000000;
  font-weight: 400;
  font-size: 20px;
  font-family: ${fontMerriweather};
  font-style: normal;
  line-height: normal;
  line-height: 140%;

  ${Columnist}+& {
    margin-block-start: 12px;
  }

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 20px;

    font-size: 28px;
  }

  ${theme.breakpoints.up("desktop")} {
    font-size: 32px;
  }

  ${props => {
    switch (props.$magazineVariant) {
      // TODO: Will add back postMag style later: case section.postMagazine.entityId
      case section.thisWeekInAsia.entityId:
        return css`
          margin-block-start: 10px;
          ${theme.breakpoints.up("tablet")} {
            margin-block-start: 14px;
          }

          ${StyledContentHeadline} {
            font-weight: 800;
            font-size: 26px;
            font-family: ${fontHeebo};
            line-height: 33px;

            ${theme.breakpoints.up("tablet")} {
              font-size: 40px;
              line-height: 48px;
            }
          }
        `;
    }
  }}
`;

export const SubHeadline = styled.div`
  grid-area: sub-headline;

  margin-block-start: 16px;

  color: #333333;
  font-weight: 400;
  font-size: 13px;
  font-family: ${fontMerriweather};
  font-style: normal;
  line-height: 140%;
  font-variant-numeric: lining-nums proportional-nums;

  :empty {
    display: none;
  }

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 20px;

    font-size: 18px;
  }

  ${StyledLi} {
    ::before {
      display: none;
    }
  }

  ${StyledUl} {
    padding-inline-start: 0;

    ${StyledLi} + ${StyledLi} {
      margin-block-start: 12px;

      ${theme.breakpoints.up("tablet")} {
        margin-block-start: 16px;
      }
    }
  }
`;

export const Sponsor = styled.div`
  grid-area: sponsor;

  margin-block-start: 16px;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 20px;
  }

  :empty {
    display: none;
  }

  ${SponsorTagContainer} {
    ${SponsorType}, ${SponsorName} {
      color: #999999;
      font-size: 12px;
      font-family: ${fontRoboto};
      font-variant-numeric: lining-nums proportional-nums;

      font-style: normal;
      line-height: normal;

      ${theme.breakpoints.up("tablet")} {
        font-size: 14px;
      }
    }

    ${SponsorType} {
      font-weight: 400;
    }

    ${SponsorName} {
      font-weight: 700;
    }
  }
`;

export const ReadingTime = styled.div`
  grid-area: reading-time;

  display: flex;
  flex-flow: row;

  margin-block-start: 16px;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 20px;
  }
`;

export const ActionWidgets = styled.div`
  grid-area: action-widgets;

  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  align-items: center;

  margin-block-start: 16px;

  ${theme.breakpoints.only("mobile")} {
    display: none;
  }

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 20px;
  }
`;

export const Leading = styled.div`
  grid-area: leading;

  margin-block: 20px;

  ${theme.breakpoints.up("tablet")} {
    margin-block: 32px;
  }

  ${ArticleImageDescriptionContainer} {
    padding: 0;
  }

  ${ArticleLeadingVideoContainer} {
    block-size: auto;

    background-color: transparent;
  }

  ${SCMPYoutubeVideoContainer} {
    ${VideoContainer} {
      inline-size: auto;
    }
  }

  ${StyledSCMPYouTubeVideoFooter} {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    max-inline-size: 656px;
    margin-inline: auto;

    ${StyledVideoRecorder} {
      path {
        fill: #666666;
      }
    }

    ${VideoTitle} {
      color: #666666;
      font-size: 16px;
      line-height: 22.4px;
    }
  }
`;

export const Author = styled.div`
  grid-area: author;

  display: flex;
  justify-content: space-between;

  border-block-start: 1px solid #000000;

  padding-block-start: 12px;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 100%;
    max-inline-size: 656px;
    margin-inline: auto;
    padding-block-start: 16px;
  }

  ${AuthorNamesContainer} {
    line-height: normal;
    ${StyledAuthorName}, ${AuthorNameEntityLink}, ${AuthorLocation}, ${CommaSeparator}, ${AndSeparator} {
      color: #000000;
      font-size: 14px;
      font-family: ${fontRoboto};
      font-style: normal;
      line-height: normal;
      font-variant-numeric: lining-nums proportional-nums;
    }

    ${AuthorLocation} {
      color: #000000;
      font-weight: 400;
    }

    ${CommaSeparator}, ${AndSeparator} {
      font-weight: 700;
    }

    ${StyledAuthorName}, ${AuthorNameEntityLink} {
      text-decoration-line: underline;

      font-weight: 700;
    }
  }
`;

export const PublishDateTime = styled.div`
  grid-area: publish-datetime;

  margin-block-start: 8px;

  line-height: normal;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 100%;
    max-inline-size: 656px;
    margin-inline: auto;
  }
`;

export const SeriesInline = styled.div`
  grid-area: series-inline;

  margin-block-start: 20px;

  :empty {
    display: none;
  }

  ${theme.breakpoints.up("tablet")} {
    inline-size: 100%;
    max-inline-size: 656px;
    margin-block-start: 32px;
    margin-inline: auto;
  }
`;

export const SeriesCarousel = styled.div`
  grid-area: series-carousel;

  margin-block-start: 32px;

  :empty {
    display: none;
  }

  :empty {
    display: none;
  }

  ${theme.breakpoints.up("tablet")} {
    inline-size: 100%;
    max-inline-size: 656px;
    margin-inline: auto;
  }
`;

export const Correction = styled.div`
  grid-area: correction;

  display: flex;

  margin-block-start: 20px;

  :empty {
    display: none;
  }

  ${theme.breakpoints.up("tablet")} {
    inline-size: 100%;
    max-inline-size: 656px;
    margin-block-start: 32px;
    margin-inline: auto;
  }

  ${LatestCorrectionDateContainer} {
    display: flex;
    align-items: center;

    inline-size: fit-content;
    padding-block: 6px;
    padding-inline: 8px;

    background-color: #ecf3ff;
  }

  ${LatestCorrectionDateContainer}:after {
    position: relative;

    margin-block-start: 0;
  }

  ${LatestCorrectionDate}, ${Item} {
    font-size: 12px;
    line-height: normal;
    text-decoration: none;

    ${theme.breakpoints.up("tablet")} {
      font-size: 14px;
    }
  }
`;

export const Content = styled.div`
  grid-area: content;

  margin-block-start: 20px;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 100%;
    max-inline-size: 656px;
    margin-block-start: 32px;
    margin-inline: auto;
  }

  ${props => pageStyle(props)}

  ${PhotoGalleryContainer} {
    padding-block: 12px;

    ${theme.breakpoints.up("tablet")} {
      padding-block: 16px;
    }
  }

  h1,
  h2,
  h3 {
    padding-block: 12px 8px;

    font-size: 20px;
    line-height: 26px;

    ${theme.breakpoints.up("tablet")} {
      padding-block: 16px 12px;

      font-size: 24px;
      line-height: 31.2px;
    }

    ${StyledAnchor} {
      color: #000000;
      font-weight: 700;
      font-size: 20px;
      font-family: ${fontRoboto};
      line-height: 26px;
      text-decoration-color: #2c4692;
      ${theme.breakpoints.up("tablet")} {
        font-size: 24px;
        line-height: 31.2px;
      }
    }
  }

  h4 {
    padding-block: 0 8px;

    font-size: 16px;
    line-height: 20.8px;

    ${theme.breakpoints.up("tablet")} {
      font-size: 20px;
      line-height: 26px;
    }

    ${StyledAnchor} {
      color: #000000;
      font-weight: 700;
      font-size: 16px;
      font-family: ${fontRoboto};
      line-height: 20.8px;
      text-decoration-color: #2c4692;
      ${theme.breakpoints.up("tablet")} {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  ${StyledParagraph} {
    font-size: 16px;
    line-height: 28.8px;

    ${theme.breakpoints.up("desktop")} {
      font-size: 18px;
      line-height: 32.4px;
    }
  }

  ${StyledAnchor} {
    color: #2c4692;
    font-weight: 400;
    font-size: 16px;
    font-family: ${fontMerriweather};
    line-height: 28.8px;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    > span {
      border-block-end: 1px solid transparent;

      &:hover {
        color: #2c4692;
      }
    }

    ${theme.breakpoints.up("desktop")} {
      font-size: 18px;
      line-height: 32.4px;
    }
  }

  .methode-html-wrapper {
    margin-block: 20px;
    padding-block: 12px;

    ${StyledGenericIframe} {
      /** horizontally center align the content of Facebook Post */
      &[src*="www.facebook.com/plugins/post"] {
        ${theme.breakpoints.up("tablet")} {
          inline-size: revert-layer;
          margin-inline: auto;
        }
      }
    }

    ${theme.breakpoints.up("tablet")} {
      padding-block: 16px;
    }

    ${InstagramContainer} {
      ${theme.breakpoints.up("desktop")} {
        margin-block: 0;
      }
    }
  }

  .video-wrapper {
    padding-block: 12px;

    ${theme.breakpoints.up("tablet")} {
      padding-block: 16px;
    }
  }

  ${StyledDivContainer}:has(${StyledGenericIframe}[src*="https://www.youtube.com"]), ${SCMPYoutubeVideoContainer}
  {
    aspect-ratio: auto;

    ${StyledGenericIframe} {
      &[src*="https://www.youtube.com"]
      {
        min-inline-size: 240px;
        max-inline-size: 600px;
        margin-inline: auto;
        aspect-ratio: 16 / 9;

        ${theme.breakpoints.up("tablet")} {
          inline-size: 100%;
          max-inline-size: none;
        }
      }
    }

    ${SCMPYouTubeContainer} {
      inline-size: calc(100% - 40px);
      max-inline-size: 600px;
      margin-inline: auto;

      ${theme.breakpoints.up("tablet")} {
        inline-size: 100%;
        max-inline-size: none;
      }
    }

    ${PreviewContainer} {
      inline-size: calc(100% - 40px);
      block-size: fit-content;
      min-inline-size: 240px;
      max-inline-size: 600px;
      margin-inline: auto;

      ${theme.breakpoints.up("tablet")} {
        inline-size: 100%;
        max-inline-size: none;
      }

      ${PreviewImage} {
        block-size: auto;

        ${theme.breakpoints.up("tablet")} {
          block-size: 100%;
        }
      }

      ${PreviewContentContainer} {
        padding: 16px;
      }
    }

    ${VideoTitleContainer} {
      inline-size: calc(100% - 40px);
      min-inline-size: 240px;
      max-inline-size: 600px;
      margin-block: 0;
      margin-inline: auto;
      padding-block: 12px 11px;
      border-block-end: 1px solid #000000;

      ${theme.breakpoints.up("tablet")} {
        align-items: center;

        inline-size: 100%;
        max-inline-size: none;
      }

      ${VideoTitle} {
        color: #000000;
        font-size: 16px;
        line-height: 22.4px;
      }
    }
  }

  ${StyledDivContainer}:has(${StyledGenericIframe}[src*="https://www.youtube.com"])
  {
    ${VideoTitleContainer} {
      inline-size: 100%;
    }
  }

  ${StyledTableContainer} {
    margin-block: 20px;
    padding-block: 12px;

    ${StyledTable} {
      table-layout: fixed;
    }

    ${theme.breakpoints.up("tablet")} {
      padding-block: 16px;
    }

    ${StyledSummary} {
      inline-size: fit-content;
      margin-block-end: 16px;
      padding-block-start: 7px;

      font-size: 16px;
      line-height: 19px;
      border-block-start: 1px solid #000000;
    }

    ${Caption} {
      margin-block-end: 16px;

      color: #000000;
      font-size: 14px;
      line-height: 16px;
    }

    ${StyledThead} {
      ${StyledTr} {
        background-color: #c8ced5;

        ${StyledTh} {
          min-inline-size: 83px;
          padding-block: 16px;
          padding-inline: 12px;

          font-size: 14px;
          line-height: 16px;
          text-align: start;
          word-wrap: break-word;
        }
      }
    }

    ${StyledTbody} {
      ${StyledTr} {
        &:nth-child(odd) {
          background-color: #f2f2f2;
        }
        &:nth-child(even) {
          background-color: #ffffff;
        }
      }

      ${StyledTd} {
        min-inline-size: 83px;
        padding-block: 16px;
        padding-inline: 12px;

        font-size: 14px;
        line-height: 16px;
        text-align: start;
        word-wrap: break-word;
      }
    }

    ${TableFooter} {
      display: flex;
    }
  }

  ${ArticleImageContainer} {
    position: static;
    inset-inline: auto;

    inline-size: auto;
    margin-block: 20px;
    margin-inline: 0;
    padding-block: 12px;

    ${DescriptionContainer} {
      padding-inline: 0;

      color: #666666;
      font-size: 12px;
      line-height: 15.6px;

      ${theme.breakpoints.up("tablet")} {
        font-size: 14px;
        line-height: 18.2px;
      }
    }
  }
`;

export const MoreOnThis = styled.div`
  grid-area: more-on-this;

  margin-block-start: 40px;

  :empty {
    display: none;
  }

  ${theme.breakpoints.up("tablet")} {
    inline-size: 100%;
    max-inline-size: 656px;
    margin-inline: auto;
  }

  ${theme.breakpoints.up("desktop")} {
    display: none;
  }
`;

export const BottomCarousel = styled.div`
  grid-area: bottom-carousel;

  margin-block-start: 40px;

  :empty {
    display: none;
  }

  ${theme.breakpoints.only("mobile")} {
    margin-block-start: 12px;
    ${setFullViewportWidthStyles()}

    ${StyledArticleCarouselWidget} {
      padding-inline: 20px;
    }
  }

  ${theme.breakpoints.up("tablet")} {
    inline-size: 100%;
    max-inline-size: 656px;
    margin-block-start: 20px;
    margin-inline: auto;
  }

  ${theme.breakpoints.up("desktop")} {
    display: none;
  }
`;

export const AuthorDetail = styled.div`
  grid-area: author-detail;

  margin-block-start: 40px;
  padding-block-start: 16px;
  border-block-start: 1px solid #000000;

  :empty {
    display: none;
  }

  ${theme.breakpoints.up("tablet")} {
    inline-size: 100%;
    max-inline-size: 656px;
    margin-inline: auto;
  }
`;

export const WidgetsBottom = styled.div`
  grid-area: widgets-bottom;
`;

export const DesktopHeaderAdSlot = styled(ArticleHeaderAdSlot)`
  grid-area: header-ad;

  display: none;

  ${theme.breakpoints.up("desktop")} {
    display: block;
  }

  ${setFullContentWidthStyles()}

  ${AdSlotContainer} {
    display: flex;
    align-items: center;
  }
`;

export const MobileContentHeaderAd = styled.div`
  grid-area: mobile-content-header-ad;

  display: none;

  ${theme.breakpoints.down("desktop")} {
    display: block;

    inline-size: 100%;
    max-inline-size: fit-content;
    margin-block-start: 20px;
    margin-inline: auto;
  }

  ${AdSlotContainer} {
    block-size: 100px;
  }
`;

export const RightAvailableSpaceTracker = styled.div`
  grid-row: leading-start / author-detail-end;
  grid-column: span 1;

  display: none;

  inline-size: 0;

  ${theme.breakpoints.up("desktop")} {
    display: flex;
  }
`;

export const FurtherReadingInlineContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-block-end: 20px;

  :empty {
    display: none;
  }

  ${theme.breakpoints.up("desktop")} {
    display: none;
  }
`;

export const PaywallContainer = styled.div`
  grid-area: paywall;
`;

export const StyledContentNewsletterWidget = styled(ContentNewsletterWidget)`
  padding: 20px;

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;
