import { useMeasure } from "@react-hookz/web";
import { type FunctionComponent, useMemo } from "react";

type Payload = {
  firstAdSlotSize?: number;
  isEnabled?: boolean;
  specs: {
    adSlotSize: number;
    whitespaceAllowance: number;
    widgets?: WidgetConfig[];
  };
  trackers: {
    right: FunctionComponent<{ ref?: React.LegacyRef<HTMLDivElement> }>;
  };
};

type WidgetConfig = {
  isShow?: boolean;
  maxHeight: number;
  name: string;
  withAdSlot?: boolean;
};

export const useReduceWhitespaceByAvailableSpace = ({
  firstAdSlotSize = 1000,
  isEnabled = true,
  specs: { adSlotSize, whitespaceAllowance, widgets = [] },
  trackers: { right: RightTracker },
}: Payload) => {
  const [rightAvailableSpaceMeasurement, rightAvailableSpaceMeasurementReference] =
    useMeasure<HTMLDivElement>(isEnabled);

  const { shouldRenderWidgets } = useMemo(() => {
    const rightAvailableSpaceMeasurementHeight = rightAvailableSpaceMeasurement?.height ?? 0;
    const availableSpaceAfterTopAds =
      rightAvailableSpaceMeasurementHeight + whitespaceAllowance - firstAdSlotSize;

    return widgets.reduce<{
      shouldContinue: boolean;
      shouldRenderWidgets: Record<string, boolean>;
      spaceLeft: number;
    }>(
      ({ shouldContinue, shouldRenderWidgets, spaceLeft }, item) => {
        const actualAdSlotSize = (item.withAdSlot ?? true) ? adSlotSize : 0;
        const requiredHeight = (item.isShow ?? true) ? item.maxHeight + actualAdSlotSize : 0;
        const shouldRender = shouldContinue && spaceLeft - requiredHeight > 0;

        return {
          shouldContinue: shouldContinue && shouldRender,
          shouldRenderWidgets: {
            ...shouldRenderWidgets,
            [item.name]: shouldRender,
          },
          spaceLeft: shouldRender ? spaceLeft - requiredHeight : spaceLeft,
        };
      },
      {
        shouldContinue: true,
        shouldRenderWidgets: {},
        spaceLeft: availableSpaceAfterTopAds,
      },
    );
  }, [
    adSlotSize,
    firstAdSlotSize,
    rightAvailableSpaceMeasurement?.height,
    whitespaceAllowance,
    widgets,
  ]);

  return {
    reduceWhitespaceByAvailableSpaceSpec: {
      right: {
        shouldRender: rightAvailableSpaceMeasurement !== undefined,
        shouldRenderWidgets,
        trackerNode: <RightTracker ref={rightAvailableSpaceMeasurementReference} />,
      },
    },
  };
};
