import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { ZIndexLayer } from "shared/lib/styles";

export const Placeholder = styled.div`
  :not(:empty) {
    padding-block: 20px;
  }
`;

export const Container = styled.div`
  transform: scaleY(1);
  transform-origin: top;
  transform-style: flat;
`;

export const getStickyStyle = (stickyHeight: number) => css`
  position: sticky;
  inset-block-start: ${stickyHeight}px;
  z-index: ${ZIndexLayer.AppBar - 1};

  background: hsla(0, 0%, 97%, 0.9);

  transition: opacity 0.3s ease;
`;
